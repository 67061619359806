<template>
  <div class="pa-3">
    <v-data-table
      :headers="Headers"
      :items="cmpcs"
      class="elevation-1 tb"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>CMPC INFORMATION</b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.CPMCLists="{ item }">
        <v-btn
          small
          download="CPMC List"
          color="primary"
          :href="`/uploads/ekscda/${item.CPMCLists}`"
          target="_blank"
          >Download CPMC List</v-btn
        >
      </template>
      <template #item.actions="{ item }">
        <v-btn
          :to="{ name: 'CPMCInformation', params: { editUser: { ...item } } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <!-- <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon> -->
      </template>
       
    </v-data-table>

    <vue-excel-xlsx
      class="mt-2 text-center"
      :data="cmpcs"
      :columns="exceldatas"
      :filename="'Cpmc Data'"
      :sheetname="'Cpmc Sheets'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { cmpcData } from "@/excels";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "CMPCInformationTable",
  mixins: [snackMixin],

  data() {
    return {
      exceldatas: cmpcData,
      search: "",
      Headers: [
        { text: "Ref No", value: "CDPRef" },
        { text: "Types of Committee", value: "Committee" },
        { text: "Election Date", value: "ElectionDate" },
        { text: "Training Date", value: "TrainingDate" },
        { text: "No of CPMC/GrPMC", value: "NumberOfCpmc" },
        { text: "No of Male", value: "CPMCMaleNo" },
        { text: "Percentage of Male", value: "PercentOfMale" },
        { text: "No of Female", value: "CPMCFemaleNo" },
        { text: "Percentage of Female", value: "PercentOfFemale" },
        { text: "Vulnerability", value: "Vulnerability" },
        { text: "Registration Date", value: "RegDate" },
        { text: "No of Trained CPMC/GrPMC", value: "CPMCTrained" },
        { text: "List of CPMC/GPMC", value: "CPMCLists" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actions" },
      ],
      cmpcs: [],
    };
  },

  created() {
    apiClient
      .get("/cpmc_info?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.cmpcs = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    /*  del(item) {
      const ok = confirm(`Are sure you want to delete ${item.CPMCRefNo}?`);
      if (ok) {
        this.displayMsg(`Deleting ${item.CPMCRefNo}, please wait...`);
        apiClient
          .delete(`/cpmc_info?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.CPMCRefNo} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.cmpcs = this.cmpcs.filter((pt) => pt.id !== item.id);
              this.displayMsg(`Successfully deleted ${item.CPMCRefNo} `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    }, */
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
